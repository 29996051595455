var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "md-layout" }, [
    _c(
      "div",
      { staticClass: "md-layout-item md-size-100" },
      [
        _c(
          "md-card",
          [
            _c(
              "md-card-header",
              { staticClass: "md-card-header-text md-card-header-green" },
              [
                _c("div", { staticClass: "card-text" }, [
                  _c("h4", { staticClass: "title" }, [_vm._v("Satellite Map")]),
                ]),
              ]
            ),
            _c("md-card-content", [
              _c("div", { staticClass: "card-text" }, [
                _c("div", {
                  staticClass: "map map-big",
                  attrs: { id: "satelliteMap" },
                }),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-small-size-100" },
      [
        _c(
          "md-card",
          [
            _c(
              "md-card-header",
              { staticClass: "md-card-header-text md-card-header-green" },
              [
                _c("div", { staticClass: "card-text" }, [
                  _c("h4", { staticClass: "title" }, [_vm._v("Regular Map")]),
                ]),
              ]
            ),
            _c("md-card-content", [
              _c("div", { staticClass: "map", attrs: { id: "regularMap" } }),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-small-size-100" },
      [
        _c(
          "md-card",
          [
            _c(
              "md-card-header",
              { staticClass: "md-card-header-text md-card-header-green" },
              [
                _c("div", { staticClass: "card-text" }, [
                  _c("h4", { staticClass: "title" }, [
                    _vm._v("Custom Skin & Settings Map"),
                  ]),
                ]),
              ]
            ),
            _c("md-card-content", [
              _c("div", { staticClass: "map", attrs: { id: "customSkinMap" } }),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }